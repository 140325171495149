// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ecole-de-tennis-js": () => import("/opt/build/repo/src/pages/EcoleDeTennis.js" /* webpackChunkName: "component---src-pages-ecole-de-tennis-js" */),
  "component---src-pages-cours-paddel-js": () => import("/opt/build/repo/src/pages/CoursPaddel.js" /* webpackChunkName: "component---src-pages-cours-paddel-js" */),
  "component---src-pages-cours-tennis-js": () => import("/opt/build/repo/src/pages/CoursTennis.js" /* webpackChunkName: "component---src-pages-cours-tennis-js" */),
  "component---src-pages-events-js": () => import("/opt/build/repo/src/pages/Events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jeux-de-cartes-js": () => import("/opt/build/repo/src/pages/JeuxDeCartes.js" /* webpackChunkName: "component---src-pages-jeux-de-cartes-js" */),
  "component---src-pages-paddel-js": () => import("/opt/build/repo/src/pages/Paddel.js" /* webpackChunkName: "component---src-pages-paddel-js" */),
  "component---src-pages-photos-js": () => import("/opt/build/repo/src/pages/Photos.js" /* webpackChunkName: "component---src-pages-photos-js" */),
  "component---src-pages-restaurant-js": () => import("/opt/build/repo/src/pages/Restaurant.js" /* webpackChunkName: "component---src-pages-restaurant-js" */),
  "component---src-pages-tennis-js": () => import("/opt/build/repo/src/pages/Tennis.js" /* webpackChunkName: "component---src-pages-tennis-js" */),
  "component---src-pages-tennis-couvert-js": () => import("/opt/build/repo/src/pages/TennisCouvert.js" /* webpackChunkName: "component---src-pages-tennis-couvert-js" */),
  "component---src-pages-tennis-ete-js": () => import("/opt/build/repo/src/pages/TennisEte.js" /* webpackChunkName: "component---src-pages-tennis-ete-js" */),
  "component---src-pages-touch-tennis-js": () => import("/opt/build/repo/src/pages/TouchTennis.js" /* webpackChunkName: "component---src-pages-touch-tennis-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

